<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submit"> Сохранить  </el-button>
      <el-button type="primary" @click="submitAndExit"> Сохранить и вернуться </el-button>
    </div>
    <el-form
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="Имя" prop="name">
        <el-input v-model="form.name" @input="handleNameInput"></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Категория" prop="categories">
        <el-select
          v-model="form.categories"
          value-key="id"
          filterable
          v-el-select-loadmore="loadMoreCategories"
          multiple
          remote
          :remote-method="searchCategories"
          :loading="categoriesLoading"
          placeholder="Выбрать"
        >
          <el-option
            v-for="item in categories"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <SeoBlock
        is-with-keywords
        is-add-event
        has-set-default-flag
        :setDefault.sync="form.seo.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage.origin"
        :keywords.sync="form.seo.keywords"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

import { SEO_TYPES } from '@/constants/seo'
import { transliterate } from '@/helpers/slug'
import { debounce } from '@/helpers/debounce.js'
import SeoBlock from '@/components/moleculs/SEO.vue'

export default {
  components: { SeoBlock },
  mixins: [notifications],
  data() {
    return {
      categories: [],
      categoriesLoading: false,
      categoriesOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      form: {
        name: '',
        slug: '',
        categories: [],
        seo: {
          setDefault: false,
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        categories: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  async created() {
    await Promise.all([this.getAssortment(), this.getCategories()])
    this.categoriesSearch = debounce(this.getCategories, 500)
  },
  methods: {
    async getAssortment() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.AssortmentsActions.getById(
          this.$route.params.id,
        )
      loading.close()

      if (error) return

      this.form = {
        ...value,
        categories: value.categories.map(({ id }) => id),
        seo: value?.seo
          ? {
              ...value?.seo,
              setDefault: value?.seo?.type === SEO_TYPES.TEMPLATE,
              ogImage: value?.seo?.ogImage
                ? {
                    ...value?.seo?.ogImage,
                    origin: value?.seo?.ogImage?.original,
                  }
                : this.form?.seo?.ogImage,
            }
          : {
              ...this.form?.seo,
              setDefault: false,
            },
      }
    },
    async getCategories() {
      this.categoriesLoading = true
      const { value, error } =
        await delivery.AddeventServiceCore.CategoriesActions.getList(
          this.categoriesOptions,
        )
      this.categoriesLoading = false
      if (error) return

      this.categories = [...this.categories, ...value.data]
      this.categoriesOptions.total = value.meta.count
    },
    loadMoreCategories() {
      const { page, limit, total } = this.categoriesOptions
      if (limit * page < total) {
        this.categoriesOptions.page++
        this.getCategories()
      }
    },
    async searchCategories(search) {
      this.categoriesOptions.page = 1
      this.categoriesOptions.search = search
      this.categoriesLoading = true
      this.categories = []
      await this.categoriesSearch()
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
        })

        const data = {
          name: this.form.name,
          slug: this.form.slug,
          categoryIds: this.form.categories,
          setDefault: this.form?.seo?.setDefault,
          seo: this.form?.seo?.setDefault
            ? null
            : {
                ...this.form.seo,
                type: SEO_TYPES.DEFAULT,
                ogImage: {
                  ...this.form?.seo?.ogImage,
                  caption: this.form?.seo?.title,
                },
              },
        }

        const { error } =
          await delivery.AddeventServiceCore.AssortmentsActions.update(
            this.$route.params.id,
            data,
          )

        loading.close()

        if (error) {
          this.showNotification('Ошибка изменения ассортимента', 'error')
          return
        }

        this.showNotification('Ассортимент успешно изменен', 'success')
      })
    },
    async submitAndExit () {
      await this.submit()
      this.$router.go(-1)
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    margin-bottom: 1rem;
  }

  .form {
    & > div > label {
      text-align: left;
    }
  }
}
</style>
